// Event.js
import light from './font/SB-L.ttf';  
import medium from './font/SB-M.ttf'; 
import bold from './font/SB-B.ttf'; 
import styled from 'styled-components';
import screen1 from './images/screen1.png'; 
import google from './images/playstore.png'; 
import apple from './images/applestore.png'; 
import poster from './images/poster.png'; 
import { NavLink } from 'react-router-dom';

function EventPage(){
    return(
<div>
<HeadtDiv>
        <SubTitle>
            슈포마켓 출시!!
        </SubTitle>
        </HeadtDiv>
       <DescriptDiv>
        <DescriptDiv2>
          <Content2>
          ※이벤트 안내는 설명 아래에 있습니다.
          </Content2>
          
        </DescriptDiv2>
        
        <ImageDiv>
        <StyledImage src= {poster}/>  
        </ImageDiv>
        

     <DescriptDiv>
      <Content>
      안녕하십니까? <br/>먼저 저희 '슈포마켓'에 관심을 가져주셔서 감사드립니다. <br/> 

저희는 포항공대 중고시장 플랫폼의 불편한 점을 개선하기 위해 2023년 UGRP부터 앱 '슈포마켓'을 개발해온 포항공대 학부생입니다.

<br/> <br/>기존 교내 중고시장은 불편한 접근성, 개인 채팅 기능의 부재, 적은 카테고리 등 다양한 문제점들이 있었습니다. 이를 해결하기 위해 필요한 기능을 조사하고, 베타 테스트를 통해 피드백을 반영하여 이번에 앱을 출시하게 되었습니다.

'슈포마켓'은 기존 중고시장의 불편한 점을 개선하고, 궁극적으로는 교내의 입학과 졸업, 방학에 따른 수요와 공급 문제도 해결하고자 합니다.

<br/> <br/>하지만 좋은 플랫폼이 되기 위해서는 앱 하나만으로는 부족합니다. 교내 구성원인 여러분들의 관심과 활발한 이용이 필요하여, 이렇게 홍보를 진행하게 되었습니다.

다운로드 링크는 하단에 있으며, 앱을 사용하는 도중 불편하거나 필요한 점이 있다면 최대한 빠르게 반영하도록 하겠습니다. 여러분들의 피드백에 귀를 기울이며 더 나은 '슈포마켓'이 되기 위해 항상 노력하겠습니다.

<br/> <br/>긴 글 읽어주셔서 감사드리고, 앞으로 많은 이용 부탁드립니다!

추가적으로, 많은 사람들이 이용하면 좋겠다는 취지에서 작은 이벤트를 진행하려고 합니다. 이벤트 내용은 아래를 참조해 주시면 감사하겠습니다.
      </Content>
      
      </DescriptDiv>   
        

      <SubTitle>
            슈포마켓 출시 이벤트
        </SubTitle>
        <Content>
1. 앱을 설치후 회원가입 후 앱에 1번이상 접속하신 분 
<br/>
- 5,000원 (선착순 50명 + 추첨 10명)
</Content>
          <Content>
2. 앱을 통해 물건을 올려주신 분 <br/>
- 10,000원 (추첨 10명)
</Content>
          <Content>
3. 앱에 부족한 부분 혹은 있으면 좋겠다는 기능을 피드백 해주신 분 <br/>
- 10,000원 (추첨 5명)
</Content>
<Content>
4. 거래인증(거래 물품과 거래자 두명이 담긴 사진)을 해주신 팀 <br/>
- 인당 20,000원 (추첨 5팀)
</Content>
          <Content>
거래인증 or 피드백 메일은 supomarket@naver.com 로 보내주시면 됩니다.
</Content>
          <Content>
상품은 해당금액의 GS 상품권을 드릴 예정입니다.
        </Content>

        <SubTitle>
          기간
        </SubTitle>
        <Content>
          5/24~6/24
          </Content>
       
        
        <SubTitle>
          유의사항
        </SubTitle>

        <Content>
          1. 학교이메일 인증은 학교구성원 인증을 위해서 이루어 지는 작업이며, 개인정보는 아래의 용도 이외에는 사용되지 않습니다.
          </Content>
          <Content>
          <StyledNavLink to ={`/rules/privacy`}>
              개인정보처리방침 확인하기
              </StyledNavLink>
        </Content>
          
        <Content>          
          2. 비밀번호는 구글 firebase로 저장해 구글이 보안을 책임집니다. (저희도 개인 비밀번호는 알아낼 수 없습니다)
        </Content>
          <Content>
          3. 이메일이 올바르지만 인증메일이 오지 않는 경우는 스팸메일함을 확인해 주시기 바랍니다. <br/> (outlook 자체에서 같은 인증메일을 보내니 스팸으로 처리를 하는 경우가 있습니다.)
          </Content>
          <Content>
          4. 부적절한 물건은 계정에 제재가 있을 예정이며, 혹은 현실성이 없는 물건은 이벤트 추첨에서 제외됩니다. 
          </Content>
          <Content>
          5. 상품은 해당 이벤트가 종료되면 이메일로 연락이 갈 예정입니다.
         </Content>
         <Content>
          6. 거래인증은 저희 앱을 통해 거래를 하는 모습을 거래 물품과 거래자 두명이 담긴 사진(얼굴 모자이크 가능) 을 제출해 주시면 됩니다.
          <br/>단, 지인간의 이벤트 수령을 위한 자작거래 혹은 허위거래는 인정하지 않습니다.
         </Content>
         <Content>
         ※ 추가 문의사항은 supomarket@naver.com 으로 연락주시면 최대한 빠르게 답을 드리겠습니다.
         </Content>
        
        <SubTitle>
            앱다운로드
        </SubTitle>
        <ImageDiv>
            <a href='https://play.google.com/store/apps/details?id=com.supomarket.supo_market'>
              <StyledImage src={google} /> 
              </a>
              
              <a href='https://apps.apple.com/kr/app/supomarket/id6477733742?platform=iphone'>
              <StyledImage src={apple} />
              </a>
          </ImageDiv>
       </DescriptDiv>
</div>
      
    );
}

export default EventPage;


const StyledImage = styled.img`
  display: block;
  max-width: 100%; /* 이미지가 부모 컨테이너의 너비를 넘지 않도록 설정 */
  height: auto; /* 이미지 비율을 유지하도록 설정 */
`;

const Space = styled.div`
    height: 30px;
`;



const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  `;


const DescriptDiv = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 425 px;
    padding-top: 0px;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 10px;
`;
const DescriptDiv2 = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300 px;
    padding-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
`;

const HeadtDiv = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300 px;
    padding-top: 0px;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 10px;
`;


const SubTitle = styled.p`
  color: var(--Black, #262626);
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  line-height: 0;
  font-family: 'SB-M', sans-serif;
  padding-left: 10px;

  @font-face {
    font-family: 'SB-M';
    src: url(${medium}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;

const ImageDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 0px;
    width: 100%;
`;

const Content = styled.p`
  color: var(--Black, #262626);
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  font-family: 'SB-L', sans-serif;
  padding-left: 10px;
  text-align: left;
  overflow-wrap: break-word;
  word-break: keep-all;
  white-space: normal;

  @font-face {
    font-family: 'SB-L';
    src: url(${light}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;

const Content2 = styled.p`
  color: var(--Black, #262626);
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  font-family: 'SB-L', sans-serif;
  padding-left: 10px;
  text-align: left;
  overflow-wrap: break-word;
  word-break: keep-all;
  white-space: normal;

  @font-face {
    font-family: 'SB-L';
    src: url(${light}) format('truetype');
    font-weight: normal;
    font-style: normal;
    
  }
`;
