// Explain.js
import light from './font/SB-L.ttf';  
import medium from './font/SB-M.ttf'; 
import bold from './font/SB-B.ttf'; 
import styled from 'styled-components';
import screen1 from './images/screen1.png'; 
import google from './images/playstore.png'; 
import apple from './images/applestore.png'; 
import poster from './images/poster.png'; 
import { NavLink } from 'react-router-dom';
import MediaQuery from'react-responsive';
import alarm from './images/alarm.png'; 
import cell from './images/cell2.png'; 
import judge from './images/judge.png'; 
import modify from './images/modify.png'; 
import upload from './images/upload.png'; 
import jjim from './images/jjim.png'; 


function ExplainPage(){
    return(
<StyledDiv>
<HeadtDiv>
<MediaQuery minWidth = {1075}>
        <Title>
            슈포마켓 사용설명서
        </Title>
            </MediaQuery>
            <MediaQuery maxWidth = {1075}>
            <SubTitle>
            슈포마켓 사용설명서
        </SubTitle>
            </MediaQuery>
</HeadtDiv>
       <DescriptDiv>

       <MediaQuery minWidth = {1075}>
        <Title>
        판매자 편
        </Title>
            </MediaQuery>
            <MediaQuery maxWidth = {1075}>
            <SubTitle>
            판매자 편
        </SubTitle>
            </MediaQuery>


            <MediaQuery minWidth = {1075}>
        <SubTitle>
        1. 물건 등록
        </SubTitle>
            </MediaQuery>
            <MediaQuery maxWidth = {1075}>
            <SubsubTitle>
            1. 물건 등록
        </SubsubTitle>
            </MediaQuery>

            <ImageDiv>
 <StyledImage src={upload}/> 
</ImageDiv>


            <Content>
              주의사항 <br/><br/>
              카테고리 설정을 꼭 해줄 수 있도록 합니다.
              <br/>
              카테고리 설정을 해주어야 구매자가 카테고리 알림을 받거나 카테고리 검색을 통해 해당 물품을 찾을 수 있습니다. 
              <br/>
              알람을 켜주셨다면, 누군가 채팅을 보내면 알림이 올 것입니다.
            </Content>



            <MediaQuery minWidth = {1075}>
        <SubTitle>
        2. 물건 수정
        </SubTitle>
            </MediaQuery>
            <MediaQuery maxWidth = {1075}>
            <SubsubTitle>
            2. 물건 수정
        </SubsubTitle>
            </MediaQuery>

            <ImageDiv>
                <StyledImage src={modify}/> 
            </ImageDiv>

            <Content>
              판매 내역에서 내가 올린 물건을 수정할 수 있습니다
              <br/>
              수정하기 버튼을 누르면 가격, 이름 상태 등을 수정할 수 있습니다.
              <br/>
              판매가 완료된다면 수정하기 우측의 빨간 상태 버튼을 눌러 해당 물건의 상태를 판매완료로 바꾸시면 됩니다.
              <br/>
              판매완료로 바꾸면 구매자를 선택해주시면, 구매자가 당신과의 거래가 어땠는지 평가해줄 것입니다.
              <br/>
              만약, 판매완료가 되지 않았지만 삭제하고 싶다면 X 표시를 눌러 삭제도 가능합니다.
            </Content>

            <br/>
            <br/>

            <MediaQuery minWidth = {1075}>
        <Title>
        구매자 편
        </Title>
            </MediaQuery>
            <MediaQuery maxWidth = {1075}>
            <SubTitle>
            구매자 편
        </SubTitle>
            </MediaQuery>
            <MediaQuery minWidth = {1075}>
        <SubTitle>
        1. 거래 평가
        </SubTitle>

            </MediaQuery>
            <MediaQuery maxWidth = {1075}>
            <SubsubTitle>
            1. 거래 평가
        </SubsubTitle>
            </MediaQuery>

            <ImageDiv>
              <StyledImage src={judge}/> 
            </ImageDiv>


            <Content>
              판매자가 판매완료로 물건을 전환하면 구매한 사람에게 거래평가가 갈것입니다.
              <br/>
              거래평가는 구매자의 신용학점의 점수에 반영될 것입니다.
            </Content>


            <MediaQuery minWidth = {1075}>
        <SubTitle>
        2. 채팅&카테고리 알림
        </SubTitle>

            </MediaQuery>
            <MediaQuery maxWidth = {1075}>
            <SubsubTitle>
            2. 채팅&카테고리 알림
        </SubsubTitle>
            </MediaQuery>

            <ImageDiv>
              <StyledImage src={alarm}/> 
            </ImageDiv>


            <Content>
              알람을 허용해주셔야 이 기능들이 이용 가능합니다.
              <br/>
              채팅알람은 누군가 채팅이 오면 알림이 오게 됩니다.
              <br/>
              카테고리 알람은 내가 관심있는 카테고리의 물건이 올라오면 알려줍니다.
              <br/>
              채팅알림, 카테고리알림 모두 설정을 통해 끄고 켤수 있습니다.
            </Content>


            <MediaQuery minWidth = {1075}>
        <SubTitle>
        3. 찜하기 & 댓글기능
        </SubTitle>

            </MediaQuery>
            <MediaQuery maxWidth = {1075}>
            <SubsubTitle>
            3. 찜하기 & 댓글기능
        </SubsubTitle>
            </MediaQuery>

            <ImageDiv>
              <StyledImage src={jjim}/> 
            </ImageDiv>


            <Content>
              하트를 누르면 찜(관심목록)탭에서 볼수 있습니다.
              <br/>
              댓글은 채팅과 달리 모두가 볼 수 있습니다.
              <br/>
              공개적으로 물건에 대해 궁금한점을 묻고 싶을때 사용합니다.
            </Content>

<br/>
<br/>
            <MediaQuery minWidth = {1075}>
        <Title>
        기능 정리
        </Title>
            </MediaQuery>
            <MediaQuery maxWidth = {1075}>
            <SubTitle>
            기능 정리
        </SubTitle>
            </MediaQuery>
        
            <ImageDiv>
              <StyledImage src={cell}/> 
            </ImageDiv>


  

         <Content>
         ※ 추가 문의사항은 supomarket@naver.com 혹은 QnA 게시판으로 연락바랍니다.
         </Content>
        
        
        <SubTitle>
            앱다운로드
        </SubTitle>
        <ImageDiv>
            <a href='https://play.google.com/store/apps/details?id=com.supomarket.supo_market'>
              <StyledImage src={google} /> 
              </a>
              
              <a href='https://apps.apple.com/kr/app/supomarket/id6477733742?platform=iphone'>
              <StyledImage src={apple} />
              </a>
          </ImageDiv>
       </DescriptDiv>
</StyledDiv>
      
    );
}

export default ExplainPage;

const StyledDiv = styled.div`

margin-left: 5px;
margin-right: 5px;
`;


const StyledImage = styled.img`
  display: block;
  max-width: 80%; /* 이미지가 부모 컨테이너의 너비를 넘지 않도록 설정 */
  height: auto; /* 이미지 비율을 유지하도록 설정 */
`;

const Space = styled.div`
    height: 30px;
`;



const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  `;


const DescriptDiv = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 425 px;
    padding-top: 0px;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 10px;
`;
const DescriptDiv2 = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300 px;
    padding-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
`;

const HeadtDiv = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300 px;
    padding-top: 0px;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 10px;
`;

const Title = styled.p`
  color: var(--Black, #262626);
  font-size: 50px;
  font-style: normal;
  font-weight: normal;
  line-height: 0;
  font-family: 'SB-M', sans-serif;
  padding-left: 10px;

  @font-face {
    font-family: 'SB-M';
    src: url(${medium}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;

const SubTitle = styled.p`
  color: var(--Black, #262626);
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  line-height: 0;
  font-family: 'SB-M', sans-serif;
  padding-left: 10px;

  @font-face {
    font-family: 'SB-M';
    src: url(${medium}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;

const SubsubTitle = styled.p`
  color: var(--Black, #262626);
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  font-family: 'SB-M', sans-serif;
  padding-left: 10px;
  text-align: left;
  overflow-wrap: break-word;
  word-break: keep-all;
  white-space: normal;

  @font-face {
    font-family: 'SB-L';
    src: url(${light}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;

const ImageDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 0px;
    width: 100%;
`;

const Content = styled.p`
  color: var(--Black, #262626);
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  font-family: 'SB-L', sans-serif;
  padding-left: 10px;
  text-align: left;
  overflow-wrap: break-word;
  word-break: keep-all;
  white-space: normal;

  @font-face {
    font-family: 'SB-L';
    src: url(${light}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;

const Content2 = styled.p`
  color: var(--Black, #262626);
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  font-family: 'SB-L', sans-serif;
  padding-left: 10px;
  text-align: left;
  overflow-wrap: break-word;
  word-break: keep-all;
  white-space: normal;

  @font-face {
    font-family: 'SB-L';
    src: url(${light}) format('truetype');
    font-weight: normal;
    font-style: normal;
    
  }
`;
